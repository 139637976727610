import React, { useEffect, useRef } from 'react';
import {
	Box,
	Table,
	TableContainer,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableCaption,
	Text,
	Grid,
	Center,
	Select,
	FormLabel,
	GridItem,
	Checkbox,
	useColorModeValue,
	useBreakpointValue,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { MinusIcon, TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { IndicatorTip } from '../../Components/IndicatorTip/IndicatorTip';
import IndicatorRankingSkeleton from './IndicatorRankingSkeleton';

const RankedList = ({ data }) => {
	const elementRef = useRef(null);
	const [anoRef, setAnoRef] = React.useState(2013);
	const [anoComp, setAnoComp] = React.useState(2013);
	const [showComp, setShowComp] = React.useState(false);
	const [indicator, setIndicator] = React.useState(1);
	const [indicatorTheme, setIndicatorTheme] = React.useState('Extrema Pobreza');
	const [indicatorTooltip, setIndicatorTooltip] = React.useState(
		'Proporção da população em situação extrema pobreza [cadastradas no Cadúnico  por município]',
	);

	const [avaliableYears, setAvaliableYears] = React.useState([]);

	const isDesktop = useBreakpointValue({ base: false, md: true });

	const {
		data: dataMirrorInfo,
		isFetching: isFetchingMirrorInfo,
		isError: isErrorMirrorInfo,
	} = useQuery(
		'IndicatorsLabels',
		async () => {
			const response = await axios.get(
				'https://idsm.ufpb.br/api/api/indicadores',
			);
			return response.data;
		},
		{ refetchOnWindowFocus: true, staleTime: 1000 * 60 * 15 },
	);

	const {
		data: dataInfo,
		isFetching: isFetchingInfo,
		isError: isErrorInfo,
	} = useQuery(
		`indicatorRanking${indicator}`,
		async () => {
			const response = await axios.get(
				`https://idsm.ufpb.br/api/api/dado-indicadores/agregado/municipio/indicador/${indicator}`,
			);
			console.log(response.data);
			return response.data;
		},
		{ refetchOnWindowFocus: true, staleTime: 1000 * 60 * 5 },
	);

	const handleIndicatorChange = (event) => {
		const values = event.target.value.split(',');
		setIndicator(values[0]);
		setIndicatorTheme(values[1]);
		setIndicatorTooltip(values[2]);
	};

	useEffect(() => {
		if (!isFetchingInfo) {
			if (isErrorInfo) {
				setAvaliableYears(['Erro ao carregar os dados']);
			} else {
				console.log(dataInfo);
				const anos = Object.values(dataInfo.indicador.anos_disponiveis);
				console.log(anos);
				if(anos.length === 0){
					return
				}
				let yearsList = [];
				for (let ano in anos) {
					if (!isNaN(anos[ano])) {
						yearsList.push(anos[ano]);
					}
				}
				setAvaliableYears(yearsList);
				setAnoRef(yearsList[yearsList.length - 1]);
				setAnoComp(yearsList[0]);
			}
		}
	}, [dataInfo, isErrorInfo, indicator]);

	return (
		<Box w="100%" p={4}>
			<Center>
				<Grid templateColumns={'1fr 1fr 1fr'} gap={8}>
					<GridItem marginTop={'1.5'}>
						<FormLabel marginLeft={3}>Selecionar Indicador</FormLabel>
						<Select
							defaultValue={1}
							onChange={handleIndicatorChange}
						>
							{dataMirrorInfo?.map((item) => (
								<option
									key={item.id}
									value={[item.id, item.nome, item.descricao]}
								>
									{item.nome}
								</option>
							))}
						</Select>
					</GridItem>
					<GridItem>
						<Grid
							display={'flex'}
							alignItems={'center'}
							justifyContent={'flex-start'}
							templateColumns={'3fr 1fr'}
						>
							<FormLabel marginTop={'1.5'}>Ano de Comparação</FormLabel>
							<Checkbox
								value={showComp}
								onChange={() => setShowComp(!showComp)}
							>
								Comparar
							</Checkbox>
						</Grid>
						<Select
							value={anoComp}
							onChange={(event) => setAnoComp(event.target.value)}
						>
							{avaliableYears?.map((item) => (
								<option key={item}>{item}</option>
							))}
						</Select>
					</GridItem>
					<GridItem>
						<FormLabel marginTop={'1.5'}>Ano de Referência</FormLabel>
						<Select
							value={anoRef}
							onChange={(event) => setAnoRef(event.target.value)}
						>
							{avaliableYears?.map((item) => (
								<option key={item}>{item}</option>
							))}
						</Select>
					</GridItem>
				</Grid>
			</Center>

			<Center>
				<Table
					variant="simple"
					colorScheme="blue"
					overflowX="auto"
					maxW={1200}
				>
					<TableCaption placement="top">
						<Text fontSize="xl">{indicatorTheme}</Text>
						<Text fontSize="md">{indicatorTooltip}</Text>
					</TableCaption>
					<Thead>
						<Tr>
							<Th>Cidade</Th>
							{showComp && (
								<>
									<Th maxW={'200px'}>Indicador ({anoComp})</Th>
									<Th maxW={'200px'}>Ranking ({anoComp})</Th>
								</>
							)}
							<Th maxW={'200px'}>Indicador ({anoRef})</Th>
							<Th maxW={'200px'}>Ranking ({anoRef})</Th>
						</Tr>
					</Thead>
					{isFetchingInfo || isFetchingMirrorInfo ? (
						<IndicatorRankingSkeleton showComparison={showComp} />
					) : (
						<Tbody>
							{dataInfo?.municipios
								.filter((item) => item.dados_por_ano[anoRef.toString()] !== null)
								.sort((a, b) => {
									if (b.dados_por_ano[anoRef.toString()]?.colocacao == null) {
										return -1;
									}
									return a.dados_por_ano[anoRef.toString()]?.colocacao - b.dados_por_ano[anoRef.toString()]?.colocacao;
								})
								.map((item, index) => {
									let alteracaoNoRanking =
										item.dados_por_ano[anoComp.toString()]?.colocacao - item.dados_por_ano[anoRef.toString()]?.colocacao;
									if (item.dados_por_ano[anoComp.toString()] == null) {
										alteracaoNoRanking = 0;
									}
									return (
										<Tr key={index}>
											<Td>{item.nome}</Td>
											{showComp && (
												<>
													<Td>
														{item.dados_por_ano[anoComp.toString()] ? item.dados_por_ano[anoComp.toString()].texto : '-'}
													</Td>
													<Td>
														{item.dados_por_ano[anoComp.toString()]
															? item.dados_por_ano[anoComp.toString()].colocacao
															: '-'}
													</Td>
												</>
											)}
											<Td>
												<Box
													display={'flex'}
													justifyContent={'flex-start'}
													gap={4}
												>
													<Text>{item.dados_por_ano[anoRef.toString()] ? item.dados_por_ano[anoRef.toString()]?.texto : "-"}</Text>
												</Box>
											</Td>
											<Td>
												<Box display={'flex'} justifyContent={'flex-start'}>
													{showComp && (
														<>
															{alteracaoNoRanking == 0 ? (
																<MinusIcon color={'gray.50'} />
															) : alteracaoNoRanking > 0 ? (
																<TriangleUpIcon
																	color={'idsm.greenCoolors.base'}
																/>
															) : (
																<TriangleDownIcon
																	color={'idsm.redCoolors.base'}
																/>
															)}
															<Text
																marginLeft={2}
																color={
																	alteracaoNoRanking == 0
																		? 'gray.50'
																		: alteracaoNoRanking > 0
																		? 'idsm.greenCoolors.base'
																		: 'idsm.redCoolors.base'
																}
															>
																{alteracaoNoRanking < 0
																	? alteracaoNoRanking * -1
																	: alteracaoNoRanking}
															</Text>
														</>
													)}
													<Text marginLeft={8}>{item.dados_por_ano[anoRef.toString()] ? item.dados_por_ano[anoRef.toString()]?.colocacao : "-"}</Text>
												</Box>
											</Td>
										</Tr>
									);
								})}
						</Tbody>
					)}
				</Table>
			</Center>
		</Box>
	);
};

export default RankedList;
