import {
	Box,
	Flex,
	Text,
	IconButton,
	Stack,
	Collapse,
	Icon,
	Link,
	Image,
	Popover,
	PopoverTrigger,
	PopoverContent,
	useColorModeValue,
	useBreakpointValue,
	useDisclosure,
	Spacer,
} from '@chakra-ui/react';
import {
	HamburgerIcon,
	CloseIcon,
	ChevronDownIcon,
	ChevronRightIcon,
} from '@chakra-ui/icons';
import { Link as LinkReactRouterDom } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
import { ColorModeSwitcher } from '../../ColorModeSwitcher';
// import * as logoNPDS from '../../NPDS - Identidade Visual/id-logos/logo-cor (2).png'

export default function WithSubnavigation() {
	const { isOpen, onToggle } = useDisclosure();

	return (
		<Box zIndex={800} width="100%" maxWidth="100vw" overflowX="hidden">
			<Flex
				bg={useColorModeValue('white', 'idsm.darkBlueCoolors.7')}
				color={useColorModeValue('gray.600', 'white')}
				minH="60px"
				py={{ base: 2 }}
				px={{ base: 2, md: 4 }}
				borderBottom={1}
				borderStyle="solid"
				borderColor={useColorModeValue('gray.200', 'idsm.darkBlueCoolors.6')}
				align="center"
				width="100%"
				maxWidth="100vw"
			>
				<Flex
					flex={{ base: 1, md: 'auto' }}
					ml={{ base: -2 }}
					display={{ base: 'flex', md: 'none' }}
				>
					<IconButton
						onClick={onToggle}
						icon={
							isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
						}
						variant="ghost"
						aria-label="Toggle Navigation"
					/>
				</Flex>
				<Flex
					flex={{ base: 1 }}
					justify={{ base: 'center', md: 'start' }}
					alignItems="center"
					width="100%"
				>
					<Link href={'/'}>
					<Image
						src="/headerLogoRecortada.png"
						alt="IDSM-LOGO"
						maxHeight="10"
						maxWidth="100%"
					/>
					</Link>
					
					<Spacer px={{ base: 2, md: 50 }} />

					<Flex display={{ base: 'none', md: 'flex' }} ml={{ base: 0, md: 10 }} flexWrap="wrap" flexGrow={1} justifyContent="flex-end">
						<DesktopNav />
					</Flex>
				</Flex>
				<Stack>
					<ColorModeSwitcher justifySelf="flex-end" />
				</Stack>
				{/* <Stack
            flex={{ base: 1, md: 0 }}
            justify={'flex-end'}
            direction={'row'}
            spacing={6}>
            <Button
              as={'a'}
              fontSize={'sm'}
              fontWeight={400}
              variant={'link'}
              href={'#'}>
              Sign In
            </Button>
            <Button
              display={{ base: 'none', md: 'inline-flex' }}
              fontSize={'sm'}
              fontWeight={600}
              color={'white'}
              bg={'blue.400'}
              href={'#'}
              _hover={{
                bg: 'blue.300',
              }}>
              Sign Up
            </Button>
          </Stack> */}
			</Flex>

			<Collapse in={isOpen} animateOpacity>
				<MobileNav />
			</Collapse>
		</Box>
	);
}

function DesktopNav() {
	const linkColor = useColorModeValue('idsm.darkBlueCoolors.base', 'gray.50');
	const linkHoverColor = 'idsm.lightBlueCoolors.base';
	const popoverContentBgColor = useColorModeValue(
		'white',
		'idsm.darkBlueCoolors.7',
	);

	return (
		<Stack direction="row" spacing={{ base: 2, md: 4 }} userSelect="none" flexWrap="wrap" justifyContent="flex-end">
			{NAV_ITEMS.map((navItem) => (
				<Box key={navItem.label}>
					<Popover trigger="hover" placement="bottom-start">
						<PopoverTrigger>
							<Link
								p={2}
								as={LinkReactRouterDom}
								to={navItem.href ?? '#'}
								fontSize={{ base: 'xs', md: 'sm' }}
								fontWeight={500}
								color={linkColor}
								_hover={{
									textDecoration: 'none',
									color: linkHoverColor,
								}}
								whiteSpace="nowrap"
							>
								{navItem.label}
							</Link>
						</PopoverTrigger>

						{navItem.children && (
							<PopoverContent
								border={0}
								boxShadow="xl"
								bg={popoverContentBgColor}
								p={4}
								rounded="xl"
								minW="sm"
							>
								<Stack>
									{navItem.children.map((child) => (
										<DesktopSubNav key={child.label} {...child} />
									))}
								</Stack>
							</PopoverContent>
						)}
					</Popover>
				</Box>
			))}
		</Stack>
	);
}

function DesktopSubNav({ label, href, subLabel }) {
	return (
		<Link
			href={href}
			role="group"
			display="block"
			p={2}
			rounded="md"
			
			_hover={{
				bg: useColorModeValue(
					'idsm.lightBlueCoolors.base',
					'idsm.darkBlueCoolors.base',
				),
				color: 'gray.50',
			}}
		>
			<Stack direction="row" align="center">
				<Box >
					<Text
						transition="all .3s ease"
						_groupHover={{
							color: useColorModeValue('gray.50', 'idsm.lightBlueCoolors.base'),
						}}
						fontWeight={500}
					>
						{label}
					</Text>
					<Text fontSize="sm">{subLabel}</Text>
				</Box>
				<Flex
					transition="all .3s ease"
					transform="translateX(-10px)"
					opacity={0}
					_groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
					justify="flex-end"
					align="center"
					flex={1}
				>
					<Icon color="blue.400" w={5} h={5} as={ChevronRightIcon} />
				</Flex>
			</Stack>
		</Link>
	);
}

function MobileNav() {
	return (
		<Stack
			bg={useColorModeValue('white', 'idsm.darkBlueCoolors.7')}
			p={4}
			display={{ md: 'none' }}
		>
			{NAV_ITEMS.map((navItem) => (
				<MobileNavItem key={navItem.label} {...navItem} />
			))}
		</Stack>
	);
}

function MobileNavItem({ label, children, href }) {
	const { isOpen, onToggle } = useDisclosure();

	return (
		<Stack spacing={4} onClick={children && onToggle}>
			<Flex
				py={2}
				as={Link}
				href={href ?? '#'}
				justify="space-between"
				align="center"
				_hover={{
					textDecoration: 'none',
				}}
			>
				<Text
					fontWeight={600}
					color={useColorModeValue('gray.600', 'gray.200')}
				>
					{label}
				</Text>
				{children && (
					<Icon
						as={ChevronDownIcon}
						transition="all .25s ease-in-out"
						transform={isOpen ? 'rotate(180deg)' : ''}
						w={6}
						h={6}
					/>
				)}
			</Flex>

			<Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
				<Stack
					mt={2}
					pl={4}
					borderLeft={1}
					borderStyle="solid"
					borderColor={useColorModeValue('gray.200', 'idsm.darkBlueCoolors.7')}
					align="start"
				>
					{children &&
						children.map((child) => (
							<Link key={child.label} py={2} href={child.href}>
								{child.label}
							</Link>
						))}
				</Stack>
			</Collapse>
		</Stack>
	);
}

const NAV_ITEMS = [
	{
		label: 'Início',
		href: '/',
	},
	{
		label: 'Mapa de Indicadores',
		href: '/map',
	},
	{
		label: 'Ranking dos Municípios',
		children: [
			{
				label: 'Ranking geral',
				subLabel: 'Consulte quais municípios estão mais alinhados aos ODS',
				href: '/ranking/general',
			},
			{
				label: 'Ranking por indicador',
				subLabel: 'Descubra quais municípios se destacam em cada indicador',
				href: '/ranking/indicador',
			},
		],
	},
	{
		label: 'Indicadores',
		children: [
			{
				label: 'Série Histórica',
				subLabel: 'Acompanhe a evolução dos municípios ao longo dos anos',
				href: '/history',
			},
		],
	},
	// {
	// 	label: 'Downloads',
	// 	href: '/database',
	// },
	{
		label: 'Metodologia',
		href: '/methodology',
	},
	{
		label: 'Equipe',
		href: '/team',
	},
	{
		label: 'Observatório',
		href: '/observatory',
	},
];

//   const NAV_ITEMS= [
//     {
//       label: 'Inspiration',
//       children: [
//         {
//           label: 'Explore Design Work',
//           subLabel: 'Trending Design to inspire you',
//           href: '#',
//         },
//         {
//           label: 'New & Noteworthy',
//           subLabel: 'Up-and-coming Designers',
//           href: '#',
//         },
//       ],
//     },
//     {
//       label: 'Find Work',
//       children: [
//         {
//           label: 'Job Board',
//           subLabel: 'Find your dream design job',
//           href: '#',
//         },
//         {
//           label: 'Freelance Projects',
//           subLabel: 'An exclusive list for contract work',
//           href: '#',
//         },
//       ],
//     },
//     {
//       label: 'Learn Design',
//       href: '#',
//     },
//     {
//       label: 'Hire Designers',
//       href: '#',
//     },
//   ];
