import {
  extendTheme,
  ChakraProvider,
  Box,
  Grid,
  Flex,
} from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import useWindowDimensions from "./Components/hooks/useWindowDimensions";
import { queryClient } from "./Services/queryClient";
import theme from "./styles/theme";

// const theme = extendTheme({theme})

function App() {
  const { height, width } = useWindowDimensions();
  // const gridRef = useRef(null)
  // const gridDimensions = useDimensions(gridRef)
  // const headerRef = useRef(null)
  // const headerDimensions = useDimensions(headerRef, true)
  // const footerRef = useRef(null)
  // const footerDimensions = useDimensions(footerRef, true)
  // if(gridDimensions && footerDimensions && headerDimensions){
  //   console.log(gridDimensions.borderBox.height - (headerDimensions.borderBox.height + headerDimensions.borderBox.height), gridDimensions.borderBox.height, headerDimensions.borderBox.height, footerDimensions.borderBox.height)
  // }
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <Box textAlign="center" fontSize="xl" overflowX="hidden">
          <Flex minHeight="100vh" width="100%" flexDirection="column">
            <Navbar />
            <Grid flex="1" width="100%"  maxWidth="100vw" overflowX="hidden">
              <Outlet />
            </Grid>
            <Footer />
          </Flex>
        </Box>
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;
