import { useState, useEffect, useRef } from 'react';
import {
	Center,
	MenuButton,
	Select,
	Box,
	Text,
	Grid,
	Flex,
	Table,
	Thead,
	Tbody,
	// Tfoot,
	Tr,
	Th,
	Td,
	TableCaption,
	TableContainer,
	useColorModeValue,
	FormControl,
	FormLabel,
	Input,
	useDisclosure,
	Button,
	Menu,
	MenuItem,
	MenuList,
	Modal,
	ModalCloseButton,
	ModalHeader,
	ModalContent,
	ModalOverlay,
	ModalFooter,
	ModalBody,
	Spinner,
	Switch,
} from '@chakra-ui/react';

import { DownloadIcon, CloseIcon, ViewIcon } from '@chakra-ui/icons';
import { useQuery } from 'react-query';
import axios from 'axios';
import FileSaver from 'file-saver';
// import { IndicatorTip } from '../../Components/IndicatorTip/IndicatorTip';

export default function History() {
	const elementRef = useRef(null);
	const {
		isOpen: isOpenDownloadModal,
		onOpen: onOpenDownloadModal,
		onClose: onCloseDownloadModal,
	} = useDisclosure();
	const {
		isOpen: isOpenYearModal,
		onOpen: onOpenYearModal,
		onClose: onCloseYearModal,
	} = useDisclosure();

	const bgColor = useColorModeValue('gray.50', 'idsm.darkBlueCoolors.7');
	// const scrollColor= useColorModeValue('gray.200', 'idsm.darkBlueCoolors.7')

	const [inputValue, setInputValue] = useState('');
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [suggestions, setSuggestions] = useState([]);
	const [selectedCity, setSelectedCity] = useState([]);
	const [avaliableYears, setAvaliableYears] = useState([]);
	const [selectedYears, setSelectedYears] = useState([]);
	const [indicator, setIndicator] = useState(1);
	const [indicatorTheme, setIndicatorTheme] = useState('Extrema Pobreza');
	const [indicatorTooltip, setIndicatorTooltip] = useState(
		'Proporção da população em situação extrema pobreza [cadastradas no Cadúnico  por município]',
	);
	const colorBgHover = useColorModeValue(
		'idsm.lightBlueCoolors.base',
		'idsm.darkBlueCoolors.base',
	);
	const colorFontHover = useColorModeValue('white', 'gray.50');
	const handleSwitchYearChange = (year) => {
		setSelectedYears((prevState) => ({
			...prevState,
			[year]: !prevState[year],
		}));
		console.log(selectedYears);
	};
	const {
		data: dataHistory,
		isFetching: isFetchingHistory,
		isError: isErrorHistory,
	} = useQuery(
		`indicatorRanking${indicator}`,
		async () => {
			const response = await axios.get(
				`https://idsm.ufpb.br/api/api/dado-indicadores/agregado/municipio/indicador/${indicator}`,
			);
			console.log(response.data);
			return response.data;
		},
		{
			refetchOnWindowFocus: true,
			staleTime: 1000 * 60 * 15, // 15 minutes
		},
	);

	async function DownloadData() {
		const response = await fetch(
			`https://idsm.ufpb.br:444/history/xlsx/processed/${indicator.toLowerCase()}`,
		);
		if (!response.ok) {
			window.alert('Ocorreu um erro ao tentar fazer o download do arquivo.');
			throw new Error('Network response was not ok');
		} else {
			const xlsxBuffer = await response.arrayBuffer();
			const blob = new Blob([xlsxBuffer], { type: 'application/octet-stream' });
			FileSaver.saveAs(
				blob,
				`serie_historica_${indicator.toLowerCase()}_normalizado.xlsx`,
			);
			return response.data;
		}
	}

	const { data: dataInfo, isFetching: isFetchingInfo } = useQuery(
		'IndicatorsLabels',
		async () => {
			const response = await axios.get(
				'https://idsm.ufpb.br/api/api/indicadores',
			);
			return response.data;
		},
		{ refetchOnWindowFocus: true, staleTime: 1000 * 60 * 15 },
	);

	const handleIndicatorChange = (event) => {
		// .codigoIndicador, item.temaIndicador
		const values = event.target.value.split(',');
		console.log(event.target.value);
		setIndicator(values[0]);
		setIndicatorTheme(values[1]);
		setIndicatorTooltip(values[2]);
	};

	useEffect(() => {
		if (!isFetchingHistory) {
			if (isErrorHistory) {
				setAvaliableYears(['Erro ao carregar os dados']);
			} else {
				const anos = dataHistory.indicador.anos_disponiveis;
				console.log(anos);
				if(anos.length === 0){
					return
				}
				let yearsList = [];
				for (let ano in anos) {
					if (!isNaN(anos[ano])) {
						yearsList.push(anos[ano]);
					}	
				}
				setAvaliableYears(yearsList);
			}
	}
	}, [dataHistory, isErrorHistory, indicator]);

	// const { isSuggestionsOpen, onToggleSuggestions } = useDisclosure()

	useEffect(() => {
		if (inputValue.length > 0) {
			inputSuggestions();
			setShowSuggestions(true);
		} else {
			setShowSuggestions(false);
		}
		console.log(showSuggestions);
	}, [inputValue]);

	const addCityFilter = (municipio) => {
		console.log(selectedCity.find((item) => item.includes(municipio)));
		if (selectedCity.find((item) => item.includes(municipio)) == undefined) {
			setSelectedCity([...selectedCity, municipio]);
		}
		setInputValue('');
	};

	const removeCityFilter = (municipio) => {
		if (selectedCity.find((item) => item.includes(municipio)) != undefined) {
			const newSelectedCity = selectedCity.filter((item) => item != municipio);
			console.log(newSelectedCity);
			setSelectedCity([...newSelectedCity]);
		}
	};

	const handleChangeInput = (event) => setInputValue(event.target.value);

	const inputSuggestions = () => {
		console.log(
			dataHistory.municipios.filter((item) => {
				// console.log(item.nomeMunicipio)
				item.nome.toLowerCase().includes(inputValue.toLowerCase());
			}),
		);
		setSuggestions(
			dataHistory.municipios.filter((item) =>
				item.nome.toLowerCase().includes(inputValue.toLowerCase()),
			),
		);
	};

	const generateTable = () => {
		let thHeader = <></>;
		let trBody = <></>;

		if (isErrorHistory) {
			return (
				<Thead alignItems="center">
					<Tr>
						<CloseIcon />
						<Text paddingBottom={6}>Erro ao carregar tabela</Text>
					</Tr>
				</Thead>
			);
		}

		if (isFetchingHistory) {
			return (
				<Thead>
					<Box padding={6}>
						<Spinner />
					</Box>
				</Thead>
			);
		}

		thHeader = <>
				<Th key={0}>Município</Th>
				<Th key={1}>Código IBGE</Th>
				{avaliableYears.map((year) => (
					<Th key={year}>{year}</Th>
				))
				}
			</>
		// thHeader = colunas.map((key) => {
		// 	if (key === 'codigoCidade') {
		// 		return <Th key={0}>Município</Th>;
		// 	} else if (key === 'nomeMunicipio') {
		// 		return <Th key={1}>Código IBGE</Th>;
		// 	} else if (key.match(/_formatado/) && selectedYears[key]) {
		// 		return <Th key={key}>{key.substr(0, 4)}</Th>;
		// 	}
		// });

		const selectedCityLength = selectedCity.length;
		trBody = dataHistory.municipios.map((item) => {
			const anos = Object.keys(item.dados_por_ano);
			const nmCol = item.nome
			const cdCol = item.codigo_ibge

			const tdBody = [];

			if (
				selectedCityLength === 0 ||
				selectedCity.includes(item.nome)
			) {
				tdBody.push(<Td key={0}>{nmCol}</Td>);
				tdBody.push(<Td key={1}>{cdCol}</Td>);
				console.log(anos)
				for (const ano of anos) {
					if (item.dados_por_ano[ano]) {
						tdBody.push(<Td key={ano}>{item.dados_por_ano[ano].texto}</Td>);
					}
				}
			}

			return <Tr key={item.nome}>{tdBody}</Tr>;
		});

		return (
			<Table>
				<Thead>{thHeader}</Thead>
				<Tbody>{trBody}</Tbody>
				{/* <Tfoot>{thHeader}</Tfoot> */}
			</Table>
		);
	};

	return (
		<Box>
			<Center>
				<FormControl maxWidth="1080px" padding={30}>
					<Grid
						templateColumns={{
							base: '1fr', // One column layout for mobile
							sm: '5.5fr 3fr 120px',
							md: '5.5fr 3fr 150px',
						}}
						gap={6}
						alignItems="flex-end"
					>
						<Grid>
							<FormLabel marginLeft={3}>Filtrar Municípios</FormLabel>
							<Menu
								isOpen={showSuggestions}
								closeOnBlur
								closeOnSelect
								autoSelect={false}
								onClose={() => setShowSuggestions(false)}
								matchWidth
							>
								<Input
									type="text"
									placeholder="Município"
									autoComplete="off"
									value={inputValue}
									onChange={handleChangeInput}
								/>
								<MenuButton as={Box} />
								<MenuList
									maxHeight="60vh"
									overflowY="auto"
									backgroundColor={bgColor}
								>
									{suggestions.length > 0 ? (
										suggestions.map((suggestion) => (
											<MenuItem
												onClick={() => addCityFilter(suggestion.nome)}
												key={suggestion.codigo_ibge}
												padding="1.5"
											>
												{suggestion.nome}
											</MenuItem>
										))
									) : (
										<MenuItem>Nenhum município encontrado</MenuItem>
									)}
								</MenuList>
							</Menu>
						</Grid>
						<Grid marginTop="1.5">
							<FormLabel marginLeft={3}>Selecionar Indicador</FormLabel>
							<Select defaultValue={1} onChange={handleIndicatorChange}>
								{isFetchingInfo ? (
									<option>carregando...</option>
								) : (
									dataInfo?.map((item) => (
										<option
										key={item.id}
										value={[item.id, item.nome, item.descricao]}
										>
											{item.nome}
										</option>
									))
								)}
							</Select>
						</Grid>
						<Button
							display="flex"
							alignItems="center"
							justifyContent="center"
							colorScheme="blue"
							maxWidth="100%"
							gap={1}
							marginTop={4}
							onClick={() => setSelectedCity([])}
						>
							<CloseIcon />
							<Text fontSize={{ base: 'smaller', sm: 'sm', md: 'md' }}>
								Limpar Filtros
							</Text>
						</Button>
					</Grid>

					<Grid
						templateColumns={{
							base: '1fr',
							sm: '5.5fr 3fr 120px',
							md: '5.5fr 3fr 150px',
						}}
						gap={6}
						alignItems="flex-end"
						paddingTop="6"
					>
						{selectedCity.length > 0 && ( // Conditionally render this Box
							<Box overflowX="auto">
								{selectedCity.map((item) => (
									<Button
										key={item}
										size="sm"
										colorScheme="gray"
										variant="solid"
										margin={2}
										rightIcon={<CloseIcon />}
										onClick={() => removeCityFilter(item)}
									>
										{item}
									</Button>
								))}
							</Box>
						)}
						{/* <Button
							onClick={onOpenYearModal}
							display="flex"
							alignItems="center"
							justifyContent="center"
							colorScheme="blue"
							gap={1}
						>
							<ViewIcon />
							<Text fontSize={{ base: 'smaller', sm: 'sm', md: 'md' }}>
								Filtrar Anos
							</Text>
						</Button>
						<Button
							onClick={DownloadData}
							display="flex"
							alignItems="center"
							justifyContent="center"
							colorScheme="blue"
							gap={1}
						>
							<DownloadIcon />
							<Text fontSize={{ base: 'smaller', sm: 'sm', md: 'md' }}>
								Baixar Dados
							</Text>
						</Button> */}
						{/* ... Modals and other components here ... */}
					</Grid>
				</FormControl>
			</Center>

			<Center marginY={5}>
				<Box
					ref={elementRef}
					flexDirection="column"
					borderColor={useColorModeValue('blue.100', 'blue.700')}
					borderWidth="2px"
					borderRadius="lg"
					padding={1}
					maxWidth="98vw"
				>
					<TableContainer
						userSelect="none"
						overflowX="auto"
						maxH="78vh"
						overflowY="auto"
					>
						<Table variant="simple" colorScheme="blue">
							<TableCaption placement="top">
								<Text fontSize="xl">{indicatorTheme}</Text>
								<Text fontSize="md">{indicatorTooltip}</Text>
							</TableCaption>
							{generateTable()}
						</Table>
					</TableContainer>
				</Box>
			</Center>
		</Box>
	);
}
