import {
  Box,
  Flex,
  Grid,
  GridItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Skeleton,
  useColorModeValue,
} from '@chakra-ui/react';

export const MethodologyItemSkeleton = () => {
  return (
    <Box
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      bg={useColorModeValue("white", "idsm.darkBlueCoolors.base")}
      borderRadius="6"
      marginTop="2"
      marginBottom="0.2rem"
      padding={["0.6rem", "0.8rem", "1rem", "0.8rem"]}
    >
      <Box>
        <Flex flexDirection={'row'} alignItems="center" gap="2">
          <Skeleton height="24px" width="24px" borderRadius="full" />
          <Skeleton height="32px" width="250px" />
        </Flex>
        <Grid templateColumns="repeat(2, 1fr)" mt={2}>
          <GridItem>
            <Flex flexDirection={'row'} alignItems="center" gap="2">
              <Skeleton height="20px" width="20px" borderRadius="full" />
              <Skeleton height="20px" width="120px" />
            </Flex>
          </GridItem>
          <GridItem>
            <Flex flexDirection={'row'} alignItems="center" gap="2">
              <Skeleton height="20px" width="20px" borderRadius="full" />
              <Skeleton height="20px" width="140px" />
            </Flex>
          </GridItem>
        </Grid>
        <Flex flexDirection={'row'} alignItems="center" gap="2" mt={2}>
          <Skeleton height="20px" width="20px" borderRadius="full" />
          <Skeleton height="20px" width="350px" />
        </Flex>
      </Box>
    </Box>
  );
};

export const MethodologySkeleton = () => {
  const axles = ['Sociais', 'Econômicos', 'Ambientais', 'Institucionais'];
  
  return (
    <Accordion allowToggle>
      {axles.map((axle, index) => (
        <AccordionItem
          key={index}
          border="1px solid"
          borderColor={useColorModeValue("gray.100", "idsm.darkBlueCoolors.8")}
          borderRadius="12"
          bg={useColorModeValue("gray.100", "idsm.darkBlueCoolors.7")}
          marginTop="4"
          marginBottom="4"
        >
          <h2>
            <AccordionButton
              borderRadius="12"
              bg={useColorModeValue("gray.100", "idsm.darkBlueCoolors.7")}
              _hover={{ backgroundColor: useColorModeValue('gray.200', 'idsm.darkBlueCoolors.base') }}
            >
              <Box
                color={useColorModeValue("idsm.darkBlueCoolors.base", "white")}
                as="span"
                flex="1"
                textAlign="left"
                fontSize="xl"
              >
                <Skeleton height="28px" width={`${180 + Math.random() * 50}px`} />
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} borderRadius="12">
            {[1, 2, 3, 4, 5].map((item) => (
              <MethodologyItemSkeleton key={item} />
            ))}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};
