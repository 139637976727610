import React from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Center,
  useBreakpointValue,
  Skeleton,
  TableContainer
} from '@chakra-ui/react';

const GRankingSkeleton = () => {
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const currentYear = new Date().getFullYear();
  
  // Generate array of 10 items for skeleton rows
  const skeletonRows = Array.from({ length: 10 }, (_, i) => i);

  if (isDesktop) {
    return (
      <Box w="100%" maxW={'100%'} p={4}>
        <Center>
          <Table
            variant="simple"
            colorScheme="blue"
            overflowX="auto"
            maxW={1200}
          >
            <TableCaption placement="top">Ranking dos Municípios</TableCaption>
            <Thead>
              <Tr>
                <Th>Posição no Ranking</Th>
                <Th>Cidade</Th>
                <Th>IDSM (2022)</Th>
              </Tr>
            </Thead>
            <Tbody>
              {skeletonRows.map((_, index) => (
                <Tr key={index}>
                  <Td>
                    <Skeleton height="20px" width="40px" />
                  </Td>
                  <Td>
                    <Skeleton height="20px" width="180px" />
                  </Td>
                  <Td>
                    <Skeleton height="20px" width="60px" />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Center>
      </Box>
    );
  } else {
    return (
      <Box w="98%" maxW={'98vw'} p={2}>
        <Center>
          <TableContainer userSelect="none" overflowX="auto">
            <Table variant="simple" colorScheme="blue">
              <TableCaption placement="top">Ranking dos Municípios</TableCaption>
              <Thead>
                <Tr>
                  <Th>Posição</Th>
                  <Th>Cidade</Th>
                  <Th>IDSM ({currentYear})</Th>
                </Tr>
              </Thead>
              <Tbody>
                {skeletonRows.map((_, index) => (
                  <Tr key={index}>
                    <Td>
                      <Skeleton height="20px" width="30px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" width="120px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" width="50px" />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Center>
      </Box>
    );
  }
};

export default GRankingSkeleton;
