import React from 'react';
import { Box, Text, Image, VStack, Heading, Container, Flex, Divider, HStack, Avatar, useColorModeValue, Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import axios from 'axios';

export default function Observatory() {
	const { postId } = useParams();

	const {
		data: postData,
		isFetching: isFetchingPostData,
		isError: isErrorPostData,
	} = useQuery(
		`postContent${postId}`,
		async () => {
			const response = await axios.get(
				`https://idsm.ufpb.br/api/api/posts/${postId}/conteudo`,
			);
			console.log('Post content:', response.data);
			return response.data;
		},
		{
			refetchOnWindowFocus: true,
			staleTime: 1000 * 60 * 60, // 60 minutes
		},
	);
	
	const {
		data: postDetails,
		isFetching: isFetchingPostDetails,
		isError: isErrorPostDetails,
	} = useQuery(
		`postDetails${postId}`,
		async () => {
			const response = await axios.get(
				`https://idsm.ufpb.br/api/api/posts/${postId}`,
			);
			console.log('Post details:', response.data);
			return response.data;
		},
		{
			refetchOnWindowFocus: true,
			staleTime: 1000 * 60 * 60, // 60 minutes
		},
	);
	
	if (isFetchingPostData || isFetchingPostDetails) {
		return (
			<Container maxW="container.md" p="4">
				<VStack spacing={8} paddingBottom={'32'}>
					{/* Title Skeleton */}
					<Skeleton 
						height="45px" 
						width="80%" 
						marginTop={'8'} 
						startColor="gray.100"
						endColor="gray.300"
					/>
					
					{/* Date Skeleton */}
					<Flex w={'100%'} justifyContent={'flex-end'}>
						<Skeleton 
							height="20px" 
							width="200px"
							startColor="gray.100"
							endColor="gray.300"
						/>
					</Flex>
					
					{/* Image Skeleton */}
					<Skeleton 
						w="100%" 
						h="400px" 
						borderRadius="md"
						startColor="gray.100"
						endColor="gray.300"
					/>
					
					{/* Content Skeletons */}
					<VStack spacing={4} width="100%">
						<SkeletonText 
							noOfLines={4} 
							spacing='4' 
							width="100%" 
							startColor="gray.100"
							endColor="gray.300"
						/>
						<SkeletonText 
							noOfLines={3} 
							spacing='4' 
							width="100%"
							startColor="gray.100"
							endColor="gray.300"
						/>
						<SkeletonText 
							noOfLines={5} 
							spacing='4' 
							width="100%"
							startColor="gray.100"
							endColor="gray.300"
						/>
					</VStack>
					
					{/* Authors Section Skeleton */}
					<Divider my={6} />
					<Box w="100%">
						<Skeleton 
							height="30px" 
							width="150px" 
							mb={4}
							startColor="gray.100"
							endColor="gray.300"
						/>
						<Flex flexWrap="wrap" justifyContent="flex-start" gap={6}>
							{[1, 2].map((index) => (
								<Box 
									key={index}
									borderWidth="1px" 
									borderRadius="md"
									p={4}
									maxW="350px"
									boxShadow="md"
									bg={useColorModeValue('white', 'idsm.darkBlueCoolors.7')}
								>
									<HStack spacing={5} align="center">
										<SkeletonCircle 
											size='16'
											startColor="gray.100"
											endColor="gray.300"
										/>
										<Box flex={1}>
											<Skeleton 
												height="20px" 
												width="150px" 
												mb={2}
												startColor="gray.100"
												endColor="gray.300"
											/>
											<Skeleton 
												height="16px" 
												width="120px" 
												mb={2}
												startColor="gray.100"
												endColor="gray.300"
											/>
											<SkeletonText 
												noOfLines={2} 
												spacing='2'
												startColor="gray.100"
												endColor="gray.300"
											/>
										</Box>
									</HStack>
								</Box>
							))}
						</Flex>
					</Box>
				</VStack>
			</Container>
		);
	}

	if (isErrorPostData || isErrorPostDetails) {
		return (
			<Container maxW="container.md" p="4">
				<VStack spacing={8} paddingBottom={'32'} alignItems="center" justifyContent="center">
					<Text color="red.500" fontSize="lg" fontWeight="medium">
						Ocorreu um erro ao carregar o post.
					</Text>
					<Text color="gray.500">
						Por favor, tente novamente mais tarde.
					</Text>
				</VStack>
			</Container>
		);
	}

	return (
		<Container maxW="container.md" p="4">
			<VStack spacing={8} paddingBottom={'32'}>
				{/* Post Title */}
				<Heading marginTop={'8'} as="h1">
					{postDetails.titulo}
				</Heading>
				
				{/* Post Date */}
				<Flex
					w={'100%'}
					flexDir={'column'}
					justifyContent={'flex-end'}
					alignItems={'flex-end'}
				>
					<Text fontSize={'xs'} as="i">
						Publicado em:{' '}
						{new Date(postDetails.data_criacao).toLocaleDateString()}
					</Text>
				</Flex>
				
				{/* Post Image */}
				{postDetails.imagem && (
					<Box w="100%" overflow="hidden" borderRadius="md">
						<Image 
							src={postDetails.imagem} 
							alt={postDetails.titulo}
							w="100%"
							objectFit="cover"
						/>
					</Box>
				)}
				
				{/* Post Content */}
				{Array.isArray(postData.conteudo) ? (
					// New structure: conteudo is an array
					postData.conteudo.sort((a, b) => a.ordem - b.ordem).map((item) => (
						<Box key={item.ordem} p="0" borderWidth="0px" borderRadius="lg" w="100%">
							{item.tipo === 1 && <Text textAlign={'left'}>{item.texto}</Text>}
							{item.tipo === 2 && <Image src={item.texto} alt={''} />}
						</Box>
					))
				) : (
					// Old structure: conteudo is an object with keys
					Object.entries(postData).map(([key, { tipo, texto }]) => (
						<Box key={key} p="0" borderWidth="0px" borderRadius="lg" w="100%">
							{tipo === 1 && <Text textAlign={'left'} fontSize={'lg'} textIndent={'2em'}>{texto}</Text>}
							{tipo === 2 && <Image src={texto} alt={''} />}
						</Box>
					))
				)}
				
				{/* Authors Section */}
				{postDetails.autores && postDetails.autores.length > 0 && (
					<>
						<Divider my={6} />
						<Box w="100%">
							<Heading as="h3" size="md" mb={4}>
								Autores
							</Heading>
							<Flex flexWrap="wrap" justifyContent="flex-start" gap={6}>
								{postDetails.autores.map((autor) => (
									<Box 
										key={autor.id} 
										borderWidth="1px" 
										borderRadius="md"
										p={4}
										maxW="350px"
										boxShadow="md"
										bg={useColorModeValue('white', 'idsm.darkBlueCoolors.7')}
									>
										<HStack spacing={5} align="center">
											<Avatar 
												size="lg" 
												name={`${autor.nome} ${autor.sobrenome}`} 
												src={autor.imagem} 
											/>
											<Box>
												<Text fontWeight="bold" fontSize="md">
													{autor.nome} {autor.sobrenome}
												</Text>
												{autor.formacao && (
													<Text fontSize="sm" color="gray.600">
														{autor.formacao}
													</Text>
												)}
												{autor.descricao && (
													<Text fontSize="sm" mt={2}>
														{autor.descricao}
													</Text>
												)}
											</Box>
										</HStack>
									</Box>
								))}
							</Flex>
						</Box>
					</>
				)}
			</VStack>
		</Container>
	);
}
