import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
  Image,
} from '@chakra-ui/react';
import { FaInstagram, FaYoutube } from 'react-icons/fa';

function Logo(props) {
  return (
    <Image
      src="headerLogo.png"
      alt="Logo"
      height="32px"
      borderRadius="full"
    />
  );
}

function SocialButton({ children, label, href }) {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded="full"
      w={8}
      h={8}
      cursor="pointer"
      as="a"
      href={href}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      transition="background 0.3s ease"
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
}

export default function SmallWithLogoLeft() {
  return (
    <Box
      zIndex={800}
      bg={useColorModeValue('white', 'idsm.darkBlueCoolors.7')}
      color={useColorModeValue('idsm.darkBlueCoolors.7', 'gray.200')}
      borderTop={1}
      borderStyle="solid"
      borderColor={useColorModeValue('gray.200', 'idsm.darkBlueCoolors.6')}
      // position='fixed'
      // left={0}
      // bottom={0}
      // position='absolute'
      bottom={0}
    >
      <Container
        as={Stack}
        maxW="6xl"
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
      >
        <SocialButton >
            <Image
                      src="logo-cor-4.png"
                      alt=""
                      height="32px"
            />
          </SocialButton>
        <Text>© 2025 IDSM PB. Todos os direitos reservados.</Text>
        <Stack direction="row" spacing={6}>
          <SocialButton
            label="YouTube"
            href="https://www.youtube.com/channel/UC_2NsvEZ1xShMzitxf33tCQ"
          >
            <FaYoutube />
          </SocialButton>
          <SocialButton
            label="Instagram"
            href="https://www.instagram.com/npdsufpb/"
          >
            <FaInstagram />
          </SocialButton>
          <SocialButton >
            <Image
                      src="headerLogo.png"
                      alt=""
                      height="32px"
            />
          </SocialButton>
          
        </Stack>
      </Container>
    </Box>
  );
}
