import React from 'react';
import GRankingSkeleton from './GRankingSkeleton';
import {
	Box,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableCaption,
	Text,
	Grid,
	Center,
	useBreakpointValue,
	Flex,
	TableContainer
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { MinusIcon, TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';

function RankedList() {
	const [anoRef, setAnoRef] = React.useState(2022);
	// const [ano2Ref, setAno2Ref] = React.useState(2020);
	const { data: dataRef, isFetching: isFetchingRef } = useQuery(
		'defaultIndexJson',
		async () => {
			const response = await axios.get(
				`https://idsm.ufpb.br/api/api/agregados/ano/${anoRef}`,
			);

			return response.data;
		},
		{ refetchOnWindowFocus: true, staleTime: 1000 * 60 * 5 },
	);

	const isDesktop = useBreakpointValue({ base: false, md: true });

	// const { data: dataRef2, isFetching: isFetchingRef2 } = useQuery(
	// 	'generalRankingJsonRef2',
	// 	async () => {
	// 		const response = await axios.get(
	// 			`https://idsm.ufpb.br:444/mirrorindexjson/${ano2Ref}`,
	// 		);

	// 		return response.data;
	// 	},
	// 	{ refetchOnWindowFocus: true, staleTime: 1000 * 60 * 5 },
	// );

	if (isFetchingRef) {
		return <GRankingSkeleton />;
	}

	if (isDesktop) {
		return (
			<Box w="100%" maxW={'100%'} p={4}>
				<Center>
					<Table
						variant="simple"
						colorScheme="blue"
						overflowX="auto"
						maxW={1200}
					>
						<TableCaption placement="top">Ranking dos Municípios</TableCaption>
						<Thead>
							<Tr>
								<Th>Posição no Ranking</Th>
								<Th>Cidade</Th>
								{/* <Th>Código Cidade</Th> */}
								<Th>IDSM ({anoRef})</Th>
								{/* <Th>IDSM ({ano2Ref})</Th>
								<Th>Posição no Ranking ({ano2Ref})</Th> */}
								{/* <Th>Alteração no ranking</Th>
				<Th>Valor do Índice</Th> */}
							</Tr>
						</Thead>
						<Tbody>
							{dataRef.municipios
								?.sort((a, b) => a.idsm.colocacao - b.idsm.colocacao)
								.map((item, index) => {
									const alteracaoNoRanking =
										item.idsm.colocacao - dataRef.municipios[index].idsm.colocacao;
									return (
										<Tr key={index}>
											<Td>
												{/* <Box display="flex" justifyContent="flex-start">
													{alteracaoNoRanking == 0 ? (
														<MinusIcon color="gray.50" />
													) : alteracaoNoRanking < 0 ? (
														<TriangleUpIcon color="idsm.greenCoolors.base" />
													) : (
														<TriangleDownIcon color="idsm.redCoolors.base" />
													)} */}
												{/* <Text
														marginLeft={2}
														color={
															alteracaoNoRanking == 0
																? 'gray.50'
																: alteracaoNoRanking < 0
																? 'idsm.greenCoolors.base'
																: 'idsm.redCoolors.base'
														}
													>
														{alteracaoNoRanking < 0
															? alteracaoNoRanking * -1
															: alteracaoNoRanking}
													</Text> */}

												{/* </Box> */}
												<Text marginLeft={8}>{item.idsm.colocacao}</Text>
											</Td>
											<Td>{item.nome}</Td>
											<Td>
												<Text>{item.idsm.valor?.toFixed(3)}</Text>
											</Td>
											{/* <Td><Text
														color={
															item["indice"] - dataRef[index].indice == 0
																? 'gray.50'
																: item["indice"] - dataRef[index].indice > 0
																? 'idsm.greenCoolors.base'
																: 'idsm.redCoolors.base'
														}
													>
														{item["indice"] - dataRef[index].indice > 0
															? (item["indice"] - dataRef[index].indice).toFixed(3)
															: (item["indice"] - dataRef[index].indice * -1).toFixed(3)}
													</Text></Td>
											<Td>{dataRef[index].indice_ranked}</Td>
											<Td>
												<Box display="flex" justifyContent="flex-start" gap={4}>
												{dataRef[index]?.indice.toFixed(3)}
													<Text>{item["indice"]?.toFixed(3)}</Text>
												</Box>
											</Td> */}

											{/* <Td>{item.changeInRank}</Td>
				  <Td>{item.indexValue}</Td> */}
										</Tr>
									);
								})}
						</Tbody>
					</Table>
				</Center>
			</Box>
		);
	} else {
		return (
			<Box w="98%" maxW={'98vw'} p={2}>
				<Center>
					<TableContainer userSelect="none"
						overflowX="auto"
					>
					<Table variant="simple" colorScheme="blue">
						<TableCaption placement="top">Ranking dos Municípios</TableCaption>
						<Thead>
							<Tr>
								<Th>Posição</Th>
								<Th>Cidade</Th>
								<Th>IDSM ({anoRef})</Th>
							</Tr>
						</Thead>
						<Tbody>
							{dataRef
								?.sort((a, b) => a['indice_ranked'] - b['indice_ranked'])
								.map((item, index) => {
									const alteracaoNoRanking =
										item['indice_ranked'] - dataRef[index].indice_ranked;
									return (
										<Tr key={index}>
											<Td>
												<Text marginLeft={2}>{item[`indice_ranked`]}</Text>
											</Td>
											<Td>{item.nomeMunicipio}</Td>
											<Td>
												<Text>{item['indice']?.toFixed(3)}</Text>
											</Td>
										</Tr>
									);
								})}
						</Tbody>
					</Table>
					</TableContainer>
				</Center>
			</Box>
		);
	}
}

export default RankedList;
