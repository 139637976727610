import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import App from './App';
import Home from './Pages/Home/Home';
import Map from './Pages/Map/Map';
import GeneralRanking from './Pages/GRanking/GRanking';
import IndicatorRanking from './Pages/IndicatorRanking/Indicatorranking';
import GoalsRank from './Pages/GoalsRank/GoalsRank';

import History from './Pages/History/History';
// import Database from './Pages/Database/Downloads';
import Database from './Pages/Database/Database';
import Post from './Pages/ObservatoryPost/Post';

import Methodology from './Pages/Methodology/Methodology';
import Team from './Pages/Team/Team';
import Observatory from './Pages/Observatory/Observatory';
import ObservatoryAdminPost from './Pages/ObservatoryAdminPost/ObservatoryAdminPost';
import DownloadsPage from './Pages/Database/Downloads';


const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		children: [
			{
				path: '/',
				element: <Home />,
			},
			{
				path: '/map',
				element: <Map />,
			},
			{
				path: '/history',
				element: <History />,
			},
			{
				path: '/database',
				element: <Database />,
			},
			{
				path: '/downloads',
				element: <DownloadsPage />,
			},
			{
				path: '/methodology',
				element: <Methodology />,
			},
			{
				path: '/team',
				element: <Team />,
			},
			{
				path: '/ranking/general',
				element: <GeneralRanking />,
			},
			{
				path: '/ranking/indicador',
				element: <IndicatorRanking />,
			},
			{
				path: '/ranking/goals',
				element: <GoalsRank />,
			},
			{
				path: '/observatory',
				element: <Observatory />,
			},
			{
				path: '/observatoryadmin4002892',
				element: <ObservatoryAdminPost />,
			},
			{
				path: '/observatory/:postId',
				element: <Post />,
			},
		],
	},
]);

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
	<StrictMode>
		<ColorModeScript />
		<RouterProvider router={router} />
	</StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
