import { Box, Card, Image, Stack, CardBody, Heading, Text, useColorModeValue, Skeleton, SkeletonText } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';

export function PostCardSkeleton() {
  return (
    <Box width={'100%'}>
      <Card
        direction={{ base: 'column', sm: 'row' }}
        overflow='hidden'
        variant='outline'
        height="250px"
        width="100%"
        minW={{ base: "100%", md: "800px" }}
        maxW="1000px"
        bg={useColorModeValue('white', 'idsm.darkBlueCoolors.7')}
        borderColor={useColorModeValue('gray.50', 'idsm.darkBlueCoolors.8')}
      >
        <Box 
          position="relative" 
          width={{ base: '100%', sm: '300px' }}
          height={{ base: '200px', sm: '250px' }}
        >
          <Skeleton
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
          />
          <Box
            position="absolute"
            bottom="0"
            left="0"
            zIndex={1}
          >
            <Skeleton height="24px" width="100px" m={2} />
          </Box>
        </Box>
        <Stack flex={1} justifyContent="space-between" width="100%">
          <CardBody width="100%" height="100%">
            <Skeleton height="24px" width="80%" mb={4} />
            <SkeletonText noOfLines={3} spacing='4' height="calc(100% - 40px)" />
          </CardBody>
        </Stack>
      </Card>
    </Box>
  );
}

export default function PostCard ({post}) {
  let navigate = useNavigate();
  const openPost = () => {
    navigate(`/observatory/${post.id}`);
  };

  return (
    <Box as='Button' width={'100%'} onClick={openPost}>
      <Card
        direction={{ base: 'column', sm: 'row' }}
        overflow='hidden'
        variant='outline'
        height="250px"
        width="100%"
        minW={{ base: "100%", md: "800px" }}
        maxW="1000px"
        _hover={{
          backgroundColor: useColorModeValue('gray.50', 'idsm.darkBlueCoolors.base'),
          borderColor: useColorModeValue('gray.200', 'idsm.darkBlueCoolors.8'),
          boxShadow: 'lg',
          transition: 'all .5s ease',
        }}
        bg={useColorModeValue('white', 'idsm.darkBlueCoolors.7')}
        borderColor={useColorModeValue('gray.50', 'idsm.darkBlueCoolors.8')}
      >
        <Box 
          position="relative" 
          width={{ base: '100%', sm: '300px' }}
          height={{ base: '200px', sm: '250px' }}
        >
          <Image
            objectFit='cover'
            src={post.imagem}
            alt={post.titulo}
            fallbackSrc="https://via.placeholder.com/300"
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
          />
          <Box
            position="absolute"
            bottom="0"
            left="0"
            bg="rgba(0, 0, 0, 0.6)"
            width="fit-content"
            zIndex={1}
          >
            <Text
              fontSize="sm"
              color="white"
              px={2}
              py={1}
            >
              {new Date(post.data_criacao).toLocaleDateString()}
            </Text>
          </Box>
        </Box>
        <Stack flex={1} justifyContent="space-between" width="100%">
          <CardBody width="100%" height="100%">
            <Heading size='md' mb={2}>{post.titulo}</Heading>
            <Text textOverflow={'clip'} lineHeight="shorter" fontSize={"lg"} textAlign={'left'} width="100%" overflow="hidden">
              {post.descricao}
            </Text>
          </CardBody>
        </Stack>
      </Card>
    </Box>
  )
}
