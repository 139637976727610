import { Box, Text, Center, Select, Flex } from '@chakra-ui/react';
import PostCard, { PostCardSkeleton } from '../../Components/PostCard/PostCard';
import { useQuery } from 'react-query';
import { useState, useEffect } from 'react';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function Observatory() {
	const [selectedEixoPost, setSelectedEixoPost] = useState(0);
	const [selectedCategoriaPost, setSelectedCategoriaPost] = useState(1);
	const [page, setPage] = useState(1);
	const [hasMore, setHasMore] = useState(true);

	const {
		data: apiResponse,
		isFetching: isFetchingPostsLabel,
		isError: isErrorPostsLabel,
		refetch,
	} = useQuery(
		[`postsEixo${selectedEixoPost}Categoria${selectedCategoriaPost}`, page],
		async () => {
			const response = await axios.get(
				`https://idsm.ufpb.br/api/api/posts`,
				{
						params: {
							eixo: selectedEixoPost,
							categoria: selectedCategoriaPost,
							page: page,
							limit: 10,
						},
				},
			);
			return response.data;
		},
		{
			refetchOnWindowFocus: false,
			staleTime: 1000 * 60 * 60, // 60 minutes
			keepPreviousData: true,
		},
	);

	const postsLabel = apiResponse?.posts || [];
	const pagination = apiResponse?.pagination || {};

	useEffect(() => {
		setPage(1);
		setHasMore(true);
		refetch();
	}, [selectedEixoPost, selectedCategoriaPost]);

	// Update hasMore based on pagination data
	useEffect(() => {
		if (pagination) {
			setHasMore(pagination.hasNextPage);
		}
	}, [pagination]);

	const fetchMoreData = () => {
		if (pagination?.hasNextPage) {
			setPage((prevPage) => prevPage + 1);
		}
	};

	return (
		<Flex
			w="100%"
			maxW={'100%'}
			p={4}
			flexDir={'column'}
			alignItems={'center'}
			marginBottom={8}
			overflowX="hidden"
		>
			<Flex
				flexDir={{ base: 'column', md: 'row' }}
				width={'100%'}
				maxWidth={'1200px'}
				justifyContent={'flex-end'}
				alignItems={'center'}
				padding={{ base: 4, md: 8 }}
				columnGap={4}
				rowGap={4}
			>
				<Select
					defaultValue={'0'}
					w={{ base: '100%', md: 200 }}
					onChange={(e) => setSelectedEixoPost(Number(e.target.value))}
				>
					<option value="0">Todos os Eixos</option>
					<option value="1">Social</option>
					<option value="2">Econômico</option>
					<option value="3">Ambiental</option>
					<option value="4">Institucional</option>
				</Select>
				<Select
					defaultValue={'1'}
					w={{ base: '100%', md: 200 }}
					onChange={(e) => setSelectedCategoriaPost(Number(e.target.value))}
				>
					<option value="1">Interno</option>
					<option value="2">Comunidade</option>
				</Select>
			</Flex>
			<Flex flexDir={'column'} gap={6} width="100%" alignItems="center">
				{isFetchingPostsLabel && (
					<Flex 
						width="100%" 
						maxWidth="1200px" 
						px={4} 
						direction="column" 
						align="center"
					>
						{[1,2].map((index) => (
							<Box 
								key={index}
								width="100%" 
								mb={6} 
								minWidth={{ base: "100%", md: "800px" }}
								maxWidth="1000px"
							>
								<PostCardSkeleton />
							</Box>
						))}
					</Flex>
				)}
				{isErrorPostsLabel && (
					<Text color="red.500" fontSize="lg" fontWeight="medium">
						Ocorreu um erro ao carregar os posts. Por favor, tente novamente.
					</Text>
				)}
				{!isFetchingPostsLabel && !isErrorPostsLabel && postsLabel.length === 0 && (
					<Text color="gray.500" fontSize="lg" fontWeight="medium" mt={8}>
						Nenhum post encontrado.
					</Text>
				)}
				{!isFetchingPostsLabel && !isErrorPostsLabel && postsLabel.length > 0 && (
					<InfiniteScroll
						dataLength={postsLabel.length}
						next={fetchMoreData}
						hasMore={hasMore}
						loader={
							<Flex 
								width="100%" 
								maxWidth="1200px" 
								px={4} 
								direction="column" 
								align="center"
								mb={6}
							>
								<Box 
									width="100%" 
									minWidth={{ base: "100%", md: "800px" }}
									maxWidth="1000px"
								>
									<PostCardSkeleton />
								</Box>
							</Flex>
						}
						endMessage={
							<Text textAlign="center" fontWeight="medium" mt={2} mb={4}>
								Não há mais posts para carregar.
							</Text>
						}
					>
						<Flex 
							width="100%" 
							maxWidth="1200px" 
							px={4} 
							direction="column" 
							align="center"
						>
							{postsLabel.map((post, index) => (
								<Box 
									key={post.id || index} 
									width="100%" 
									mb={6} 
									minWidth={{ base: "100%", md: "800px" }}
									maxWidth="1000px"
								>
									<PostCard post={post} />
								</Box>
							))}
						</Flex>
					</InfiniteScroll>
				)}
			</Flex>
		</Flex>
	);
}
