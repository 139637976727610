/* eslint-disable  */
import {
	MapContainer,
	TileLayer,
	Polygon,
	Tooltip,
	SVGOverlay,
} from 'react-leaflet';

import ReactEChartsCore from 'echarts-for-react/lib/core';

import * as echarts from 'echarts/core';

import {
	BarChart,
} from 'echarts/charts';

import {
	GridComponent,
	TooltipComponent,
	TitleComponent,
} from 'echarts/components';

import {
	CanvasRenderer,
} from 'echarts/renderers';

import 'leaflet/dist/leaflet.css';

import { useState} from 'react';

import useWindowDimensions from '../hooks/useWindowDimensions';


import {
	Grid,
	Box,
	Flex,
	useColorModeValue,
	useColorMode,
	Text,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import axios from 'axios';
import '../../styles/tooltipcss.css';
import { pbData } from '../../pbgeojson';
// import IndicatorModal from '../DataGraph/DataGraph';
import PopupAlert from '../PageLoadPopup/PageLoadPopup';

export default function IndicatorMap() {
	const [activeCity, setActiveCity] = useState(false);
	const [year, setYear] = useState(2022);
	const [indicatorVisibility, setIndicatorVisibility] = useState({
		T01S01: true,
	});
	const color = useColorModeValue('#FFFFFF', '#0a1c2d');
	const fillColor = useColorModeValue('#F7FAFC', '#0C2237');
	const [isModalIndicatorOpen, setIsModalIndicatorOpen] = useState(false);

	// const { data: dataInfo, isFetching: isFetchingInfo } = useQuery(
	// 	'indexLabelsJson',
	// 	async () => {
	// 		const response = await axios.get(
	// 			'https://idsm.ufpb.br:444/indexlabels/json',
	// 		);
	// 		return response.data;
	// 	},
	// 	{ refetchOnWindowFocus: true, staleTime: 1000 * 60 * 5 },
	// );
	const isFetchingInfo = false;

	const { data, isFetching } = useQuery(
		`idsmIndexJson${year}`,
		async () => {
			const response = await axios.get(
				`https://idsm.ufpb.br/api/api/agregados/ano/${year}`,
			);
			console.log("carregou");
			console.log(response.data);
			return response.data;
		},
		{
			refetchOnWindowFocus: true,
			staleTime: 1000 * 60 * 5, // 5 minutes
		},
	);

	const handleModalIndicatorClose = () => {
		setIsModalIndicatorOpen(false);
	};

	const dataFetched = () => {
		if (!isFetching && !isFetchingInfo) {
			return true;
		}
		return false;
	};

	const generateColor = (value) => {
		// console.log(value);
		// console.log(parseFloat(value));
		const red = 255 * (1 - value);
		const green = 255 * value;
		const blue = 0;
		return `rgb(${red},${green},${blue})`;
	};

	echarts.use([
		TitleComponent,
		TooltipComponent,
		GridComponent,
		BarChart,
		CanvasRenderer,
	]);

	const cityValue = (codigoCidade, column) => {
		if (dataFetched && data) {
			codigoCidade = codigoCidade.slice(0, -1);
			// console.log(codigoCidade);
			const cityData = data.municipios.find((city) => city.codigo_ibge == codigoCidade);
			// console.log(cityData.codigo_ibge)
			// console.log(cityData);

			if (cityData) {
				if (column === 'colocacao') {
					return cityData.idsm.colocacao;
				}
				if (column === 'IDSM') {
					return cityData.idsm.valor.toFixed(2);
				}
				return cityData.eixos[column]?.valor.toFixed(2) ? cityData.eixos[column].valor.toFixed(2) : 0;
			}
		}
		return 0;
	};

	// const [optionFetched, setOptionFetched] = useState({
	// 	// title: {
	// 	// 	text: 'Indice por eixo'
	// 	// },
	// 	tooltip: {
	// 		trigger: 'axis',
	// 		axisPointer: {
	// 			type: 'shadow',
	// 		},
	// 	},
	// 	grid: {
	// 		top: 30,
	// 		bottom: 30,
	// 	},
	// 	xAxis: {
	// 		type: 'value',
	// 		max: 1,
	// 		position: 'top',
	// 		splitLine: {
	// 			lineStyle: {
	// 				type: 'dashed',
	// 			},
	// 		},
	// 	},
	// 	yAxis: {
	// 		type: 'category',
	// 		axisLine: { show: false },
	// 		axisLabel: { show: false },
	// 		axisTick: { show: false },
	// 		splitLine: { show: false },
	// 		data: ['Social', 'Econômico', 'Ambiental', 'Institucional'],
	// 	},
	// 	series: [
	// 		{
	// 			name: 'Index',
	// 			type: 'bar',
	// 			stack: 'Total',
	// 			label: {
	// 				show: true,
	// 				formatter: '{b}',
	// 			},
	// 			data: [0, 0, 0, 0],
	// 		},
	// 	],
	// });
	return (
		<Grid height="100%">
			{/* center={[-7.186632104871331, -36.26586738668012]}  */}
			
			<MapContainer
				zoom={9}
				zoomControl={false}
				scrollWheelZoom
				attributionControl={false}
				minZoom={7}
				maxZoom={11}
				bounceAtZoomLimits
				bounds={[
					[-6.02, -35],
					[-8.19, -38.45],
				]}
			>
				<TileLayer
					url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" // 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png'//"https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png"//'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'//"https://api.maptiler.com/maps/hybrid/256/{z}/{x}/{y}.jpg?key=mSnfsmKkmFsH4b8gWyWa"
				/>
				<PopupAlert localStorageKey='mapLoadPopup' titleText='Entendendo o Mapa de Indicadores' bodyText='Esta ferramenta apresenta dados do IDSM especificamente para o seu município de forma clara e direta. Ao passar o mouse sobre um município, você obterá um breve resumo de sua classificação no IDSM (onde o município com a pior avaliação tem nota 0,00 e o melhor tem nota 1,00).' />

				{pbData.features.map((city) => {
					const coordinates = city.geometry.coordinates[0].map((item) => [
						item[1],
						item[0],
					]);
					let customColor = '';
					if (!isFetching && !isFetchingInfo) {
						const foundElement = data.municipios.find(
							(element) =>
								element.codigo_ibge == city.properties.id.slice(0, -1),
						);
						// console.log(foundElement);
						if (foundElement) {
							customColor = generateColor(foundElement.idsm.valor);
						}
					}
					return (
							
						<Polygon
							key={city.properties.id}
							pathOptions={{
								fillColor: isFetching ? fillColor : customColor, // '#2D3748' ,//'#8bdf77',
								fillOpacity: 1,
								weight: 1.6,
								opacity: 1,
								dashArray: '3',
								color,
							}}
							positions={coordinates}
							eventHandlers={{
								mouseover: (e) => {
									if (isFetching == false) {
										const found = data.municipios.find(
											(element) =>
												element.codigo_ibge == city.properties.id.slice(0, -1),
										);
										console.log(found);
									}
									const layer = e.target;
									layer.setStyle({
										weight: 5,
										fillOpacity: 1,
										dashArray: 'center',
										color,
									});
								},
								mouseout: (e) => {
									const layer = e.target;
									layer.setStyle({
										fillColor: isFetching ? fillColor : customColor, // '#2D3748' ,//'#8bdf77',
										fillOpacity: 1,
										weight: 1.6,
										opacity: 1,
										dashArray: '3',
										color,
									});
								},
								click: (e) => {

									if (isFetching == false) {
										const found = data.municipios.find(
											(element) =>
												element.nome == city.properties.id.slice(0, -1),
										);
										console.log(found);

										setActiveCity(found);
										setIsModalIndicatorOpen(true);
									}
								},
							}}
						>
							
							<Tooltip className="blackTooltip" bubblingMouseEvents opacity={1} margin={10} offset={[40, 0]}>
								<Box
									h="300px"
									w="300px"
									
									padding={0}
									bg={useColorModeValue('gray.50', 'idsm.darkBlueCoolors.base')}
									borderRadius={'2xl'}
									borderWidth={3}
									
								>
									<Flex flexDirection={'column'} height="100%" paddingTop={4}>
										<Flex
											justifyContent={'space-between'}
											// alignItems="center"
											width="100%"
											paddingX={4}
										>
											<Box width="100%" padding={0}></Box>
											<Box width="100%" textAlign="center">
												<Text
													fontSize={'md'}
													color={useColorModeValue(
														'idsm.darkBlueCoolors.base',
														'gray.50',
													)}
												>
													{city.properties.name}
												</Text>
											</Box>
											
											<Box width="100%" textAlign="center">
												<Text
													fontSize={'sm'}
													color={useColorModeValue(
														'idsm.darkBlueCoolors.base',
														'gray.50',
													)}
												>
													{cityValue(city.properties.id, 'colocacao')}º
												</Text>
											</Box>
										</Flex>
										
										<Flex height={'100%'} padding={0}>
											<ReactEChartsCore
												echarts={echarts}
												option={{
													// title: {
													// 	text: 'Indice por eixo'
													// },
													tooltip: {
														trigger: 'axis',
														axisPointer: {
															type: 'shadow',
														},
													},
													grid: {
														top: 80,
														bottom: 30,
													},
													xAxis: {
														type: 'value',
														max: 1,
														position: 'top',
														splitLine: {
															lineStyle: {
																type: 'dashed',
															},
														},
													},
													yAxis: {
														type: 'category',
														axisLine: { show: false },
														axisLabel: { show: false },
														axisTick: { show: false },
														splitLine: { show: false },
														data: [
															'IDSM',
															'Social',
															'Econômico',
															'Ambiental',
															'Institucional',
														],
													},
													series: [
														{
															name: 'Index',
															type: 'bar',
															stack: 'Total',
															label: {
																show: true,
																position: 'insideLeft',
																formatter: '{b}',
															},
															data: [
																// Here we're providing an object instead of a simple number for each bar.
																// This object has a `value` (which is the bar's height)
																// and an `itemStyle`, which lets us style this particular bar.
																{
																	value: cityValue(
																		city.properties.id,
																		'IDSM',
																	),
																	// The `itemStyle` has a `color`, which is the color of the bar.
																	itemStyle: { color: '#1b4a69' },
																},
																{
																	value: cityValue(
																		city.properties.id,
																		'1',
																	),
																	// The `itemStyle` has a `color`, which is the color of the bar.
																	itemStyle: { color: '#0873BA' },
																},
																// We do the same for each bar, giving each one a unique color.
																{
																	value: cityValue(
																		city.properties.id,
																		'2',
																	),
																	itemStyle: { color: '#0873BA' },
																},
																{
																	value: cityValue(
																		city.properties.id,
																		'3',
																	),
																	itemStyle: { color: '#0873BA' },
																},
																{
																	value: cityValue(
																		city.properties.id,
																		'4',
																	),
																	itemStyle: { color: '#0873BA' },
																},
															],
														},
													],
												}}
												notMerge
												lazyUpdate
												theme="theme_name"
												// onChartReady={onChartReadyCallback}
												// onEvents={EventsDict}
												// opts={}
												style={{ height: '100%', width: '100%' }}
											/>
										</Flex>
									</Flex>
								</Box>
							</Tooltip>
						</Polygon>
					);
				})}
			</MapContainer>
			{/* <IndicatorModal
				isOpen={isModalIndicatorOpen}
				onClose={handleModalIndicatorClose}
				activeCity={activeCity}
			/> */}
		</Grid>
	);
}
