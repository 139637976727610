import React from 'react';
import {
	Box,
	Tbody,
	Tr,
	Td,
	Skeleton,
} from '@chakra-ui/react';

const IndicatorRankingSkeleton = ({ showComparison = false }) => {
	return (
		<Tbody>
			{[...Array(12)].map((_, index) => (
				<Tr key={index}>
					<Td>
						<Skeleton height="24px" width="180px" />
					</Td>
					{showComparison && (
						<>
							<Td>
								<Box
									display={'flex'}
									justifyContent={'flex-start'}
									gap={4}
								>
									<Skeleton height="24px" width="120px" />
								</Box>
							</Td>
							<Td>
								<Box display={'flex'} justifyContent={'flex-start'}>
									<Skeleton height="24px" width="40px" />
								</Box>
							</Td>
						</>
					)}
					<Td>
						<Box
							display={'flex'}
							justifyContent={'flex-start'}
							gap={4}
						>
							<Skeleton height="24px" width="120px" />
						</Box>
					</Td>
					<Td>
						<Box display={'flex'} justifyContent={'flex-start'}>
							<Skeleton height="24px" width="40px" />
						</Box>
					</Td>
				</Tr>
			))}
		</Tbody>
	);
};

export default IndicatorRankingSkeleton;
