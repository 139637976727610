import { useState } from 'react';
import { MethodologySkeleton } from './MethodologySkeleton';
import {
	Center,
	Box,
	Text,
	Grid,
	Flex,
	useColorModeValue,
	GridItem,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Icon,
} from '@chakra-ui/react';

import { MdCheckCircle } from 'react-icons/md';
import { useQuery } from 'react-query';
import axios from 'axios';
import Legenda from '../../Components/Legenda/legenda';

export default function Methodology() {
	const { data: dataInfo, isFetching: isFetchingInfo } = useQuery(
		'indicators',
		async () => {
			const response = await axios.get(
				'https://idsm.ufpb.br/api/api/indicadores'
			);
			return response.data;
		},
		{ refetchOnWindowFocus: false, staleTime: 1000 * 60 * 60 },
	);

	const methodologyElements = () => {
		if (!dataInfo) return [];
		
		let socialIndicators = [];
		let economicIndicators = [];
		let environmentalIndicators = [];
		let institutionalIndicators = [];

		dataInfo.forEach(indicator => {
			switch (indicator.id_eixo.toString()) {
				case '1':
					socialIndicators.push(indicator);
					break;
				case '2':
					economicIndicators.push(indicator);
					break;
				case '3':
					environmentalIndicators.push(indicator);
					break;
				case '4':
					institutionalIndicators.push(indicator);
					break;
				default:
					break;
			}
		});

		const indicatorsFull = [
			socialIndicators,
			economicIndicators,
			environmentalIndicators,
			institutionalIndicators,
		].filter(array => array.length > 0);

		const acordeao = indicatorsFull.map((axle, index) => {
			let tituloEixo = '';
			switch (axle[0]?.id_eixo.toString()) {
				case '1':
					tituloEixo = 'Indicadores Sociais';
					break;
				case '2':
					tituloEixo = 'Indicadores Econômicos';
					break;
				case '3':
					tituloEixo = 'Indicadores Ambientais';
					break;
				case '4':
					tituloEixo = 'Indicadores Institucionais';
					break;
				default:
					break;
			}
			return (
				<AccordionItem
					border="1px solid"
					borderColor={useColorModeValue("gray.100", "idsm.darkBlueCoolors.8")}
					borderRadius="12"
					bg={useColorModeValue("gray.100", "idsm.darkBlueCoolors.7")}
					marginTop="4"
					marginBottom="4"
					key={index}
				>
					<h2>
						<AccordionButton
							borderRadius="12"
							bg={useColorModeValue("gray.100", "idsm.darkBlueCoolors.7")}
							_hover={{ backgroundColor: useColorModeValue('gray.200', 'idsm.darkBlueCoolors.base') }}
						>
							<Box
								color={useColorModeValue("idsm.darkBlueCoolors.base", "white")} 
								as="span"
								flex="1"
								textAlign="left"
								fontSize="xl"
							>
								{tituloEixo}
							</Box>
							<AccordionIcon />
						</AccordionButton>
					</h2>
					<AccordionPanel pb={4} borderRadius="12">
						{axle.map((indicator, index) => (
							<Box
								flexDirection="column"
								alignItems="center"
								justifyContent="center"
								bg={useColorModeValue("white", "idsm.darkBlueCoolors.base")}
								borderRadius="6"
								marginTop="2"
								marginBottom="0.2rem"
								padding={["0.6rem", "0.8rem", "1rem", "0.8rem"]}
								fontSize={["0.8rem", "1rem", "1.2rem", "1rem"]}
								key={index}
							>
								<Box>
									<Flex flexDirection={'row'} alignItems="center" gap="2">
										<Icon as={MdCheckCircle} color="idsm.greenCoolors.base" />
										<Text fontSize="lg">{indicator.nome}</Text>
									</Flex>
									<Grid templateColumns="repeat(2, 1fr)">
										<GridItem>
											<Flex flexDirection={'row'} alignItems="center" gap="2">
												<Icon as={MdCheckCircle} color="idsm.redCoolors.base" />
												<Text fontSize="md">T{indicator.id_tema.toString().padStart(2, '0')}S{indicator.subtema.toString().padStart(2, '0')}</Text>
											</Flex>
										</GridItem>
										<GridItem>
											<Flex flexDirection={'row'} alignItems="center" gap="2">
												<Icon
													as={MdCheckCircle}
													color="idsm.lightBlueCoolors.base"
												/>
												<Text fontSize="md">{indicator.fonte}</Text>
											</Flex>
										</GridItem>
									</Grid>
									<Flex flexDirection={'row'} alignItems="center" gap="2">
										<Icon
											as={MdCheckCircle}
											color="idsm.yellowCoolors.base"
										/>
										<Text fontSize="md">{indicator.descricao}</Text>
									</Flex>
								</Box>
							</Box>
						))}
					</AccordionPanel>
				</AccordionItem>
			);
		});
		return acordeao;
	};

	const legendItems = [
		{ label: 'Indicador', color: 'idsm.greenCoolors.base' },
		{ label: 'Código', color: 'idsm.redCoolors.base' },
		{ label: 'Descrição', color: 'idsm.yellowCoolors.base' },
		{ label: 'Fonte', color: 'idsm.lightBlueCoolors.base' }
	];

	return (
		<Box>
			<Center>
				<Box
					alignItems="flex-start"
					justifyContent="center"
					textAlign="initial"
					margin={4}
					w="100%"
					maxWidth="1200px"
				>
					<Text fontSize="2xl" fontWeight="bold" mb={4}  textIndent={'4em'}>Metodologia</Text>
					<Text fontSize="lg" fontWeight="normal" mb={4} textIndent={'4em'}>O IDSM é um índice desenvolvido baseado em mais de 40 dados da mais diversas áreas, tendo como foco agregar e disponibilizar o fácil acesso dados que estejam disponiveis para todos os municipios da Paraíba. Atualmente nossa base de dados armazena registros de 2013 até 2023, totalizando mais de 1 milhão de registros.</Text>
					<Text fontSize="lg" fontWeight="" mb={4} textIndent={'4em'}>Abaixo você pode ver em detalhes cada um dos indicadores dividos em quatro eixos que utilizamos para compor o IDSM e verificar a metodologia utilizada para criar o indicador.</Text>
					<Legenda legendItems={legendItems} />
					<Accordion allowToggle>
						{isFetchingInfo ? <MethodologySkeleton /> : methodologyElements()}
					</Accordion>
				</Box>
			</Center>
		</Box>
	);
}
